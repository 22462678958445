import React from 'react'
import { Card, CardBody, Flex, Spinner } from '@chakra-ui/react'
export default function LoadingComponent() {
    return <Card 
    width="100%" 
    boxShadow='xs' 
    height="100%" 
    bg={"#262626"}
    color="white"
    overflow={"hidden"}
    direction={{ base: 'column'}}
    >
        <CardBody>
            <Flex alignItems={"center"} justifyContent="center" height="100%">
                <Spinner />
            </Flex>
        </CardBody>
    </Card>
}
