import React from 'react'
import { Card, CardBody, CardHeader,Heading } from '@chakra-ui/react'

export default function DashboardItem(props) {
  return (
    <Card 
    width="100%" 
    boxShadow='xs' 
    height="100%" 
    bg={"#262626"}
    color="white"
    overflow={"hidden"}
    direction={{ base: 'column'}}
    >
        <CardHeader size='xs' as='b' height={2}>
          <Heading size='md'>{props.title}</Heading>
        </CardHeader>
        <CardBody>
            {props.children}
        </CardBody>
    </Card>
  )
}
