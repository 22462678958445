export const mockupTickerData = {
  "meta": {
    "symbol": "IBM",
    "interval": "1month",
    "currency": "USD",
    "exchange_timezone": "America/New_York",
    "exchange": "NYSE",
    "mic_code": "XNYS",
    "type": "Common Stock"
  },
  "values": [
    {
      "datetime": "2023-06-01",
      "open": "128.44000",
      "high": "139.47000",
      "low": "127.78000",
      "close": "129.97000",
      "volume": "71253333"
    },
    {
      "datetime": "2023-05-01",
      "open": "126.35000",
      "high": "130.07001",
      "low": "120.55000",
      "close": "128.59000",
      "volume": "96213349"
    },
    {
      "datetime": "2023-04-01",
      "open": "130.97000",
      "high": "132.61000",
      "low": "124.56000",
      "close": "126.41000",
      "volume": "83680219"
    },
    {
      "datetime": "2023-03-01",
      "open": "128.89999",
      "high": "131.48000",
      "low": "121.71000",
      "close": "131.09000",
      "volume": "138093085"
    },
    {
      "datetime": "2023-02-01",
      "open": "134.49001",
      "high": "137.39000",
      "low": "128.86000",
      "close": "129.30000",
      "volume": "76080679"
    },
    {
      "datetime": "2023-01-01",
      "open": "141.10001",
      "high": "147.17999",
      "low": "132.98000",
      "close": "134.73000",
      "volume": "105576020"
    },
    {
      "datetime": "2022-12-01",
      "open": "149.98000",
      "high": "153.21001",
      "low": "137.19501",
      "close": "140.89000",
      "volume": "86426226"
    },
    {
      "datetime": "2022-11-01",
      "open": "138.25000",
      "high": "150.46001",
      "low": "133.97000",
      "close": "148.89999",
      "volume": "93620235"
    },
    {
      "datetime": "2022-10-01",
      "open": "120.16000",
      "high": "138.86150",
      "low": "115.54500",
      "close": "138.28999",
      "volume": "113480787"
    },
    {
      "datetime": "2022-09-01",
      "open": "128.39999",
      "high": "130.99001",
      "low": "118.61000",
      "close": "118.81000",
      "volume": "87267136"
    },
    {
      "datetime": "2022-08-01",
      "open": "130.75000",
      "high": "139.34000",
      "low": "128.39999",
      "close": "128.45000",
      "volume": "77424007"
    },
    {
      "datetime": "2022-07-01",
      "open": "141.00000",
      "high": "141.87000",
      "low": "125.13000",
      "close": "130.78999",
      "volume": "129801062"
    },
    {
      "datetime": "2022-06-01",
      "open": "139.67000",
      "high": "144.73000",
      "low": "132.85001",
      "close": "141.19000",
      "volume": "105815743"
    },
    {
      "datetime": "2022-05-01",
      "open": "133.00000",
      "high": "139.83000",
      "low": "125.80000",
      "close": "138.84000",
      "volume": "113226528"
    },
    {
      "datetime": "2022-04-01",
      "open": "129.66000",
      "high": "141.88000",
      "low": "124.91000",
      "close": "132.21001",
      "volume": "107687898"
    },
    {
      "datetime": "2022-03-01",
      "open": "122.67000",
      "high": "133.08000",
      "low": "120.70000",
      "close": "130.02000",
      "volume": "96574253"
    },
    {
      "datetime": "2022-02-01",
      "open": "133.75999",
      "high": "138.82001",
      "low": "118.81000",
      "close": "122.51000",
      "volume": "98535967"
    },
    {
      "datetime": "2022-01-01",
      "open": "134.07001",
      "high": "142.20000",
      "low": "124.19300",
      "close": "133.57001",
      "volume": "147278350"
    },
    {
      "datetime": "2021-12-01",
      "open": "118.25000",
      "high": "134.99001",
      "low": "116.56000",
      "close": "133.66000",
      "volume": "113976826"
    },
    {
      "datetime": "2021-11-01",
      "open": "119.44326",
      "high": "124.78000",
      "low": "114.56000",
      "close": "117.10000",
      "volume": "119383279"
    },
    {
      "datetime": "2021-10-01",
      "open": "134.67813",
      "high": "139.45395",
      "low": "119.03254",
      "close": "119.49101",
      "volume": "144097757"
    },
    {
      "datetime": "2021-09-01",
      "open": "133.70386",
      "high": "134.26741",
      "low": "126.82668",
      "close": "132.70093",
      "volume": "76644238"
    },
    {
      "datetime": "2021-08-01",
      "open": "135.10794",
      "high": "138.21223",
      "low": "131.05804",
      "close": "134.04771",
      "volume": "69688548"
    },
    {
      "datetime": "2021-07-01",
      "open": "140.37090",
      "high": "140.88669",
      "low": "130.10184",
      "close": "134.63992",
      "volume": "110639110"
    },
    {
      "datetime": "2021-06-01",
      "open": "138.49878",
      "high": "145.98726",
      "low": "136.62666",
      "close": "140.01749",
      "volume": "84448985"
    },
    {
      "datetime": "2021-05-01",
      "open": "137.36214",
      "high": "141.85619",
      "low": "134.60172",
      "close": "137.29527",
      "volume": "97960800"
    },
    {
      "datetime": "2021-04-01",
      "open": "127.76273",
      "high": "142.07109",
      "low": "124.53428",
      "close": "135.51866",
      "volume": "122920494"
    },
    {
      "datetime": "2021-03-01",
      "open": "114.95399",
      "high": "130.92433",
      "low": "113.43050",
      "close": "127.28516",
      "volume": "127967821"
    },
    {
      "datetime": "2021-02-01",
      "open": "114.52416",
      "high": "118.77464",
      "low": "112.82397",
      "close": "113.59766",
      "volume": "106727235"
    },
    {
      "datetime": "2021-01-01",
      "open": "120.20739",
      "high": "126.31089",
      "low": "112.09805",
      "close": "113.76958",
      "volume": "176189106"
    },
    {
      "datetime": "2020-12-01",
      "open": "118.34482",
      "high": "121.96489",
      "low": "116.26257",
      "close": "120.23605",
      "volume": "102831729"
    },
    {
      "datetime": "2020-11-01",
      "open": "107.59923",
      "high": "119.69447",
      "low": "106.17603",
      "close": "117.98186",
      "volume": "103684157"
    },
    {
      "datetime": "2020-10-01",
      "open": "116.87386",
      "high": "129.42473",
      "low": "101.17097",
      "close": "106.65361",
      "volume": "159130910"
    },
    {
      "datetime": "2020-09-01",
      "open": "117.34190",
      "high": "124.12357",
      "low": "111.25750",
      "close": "116.21481",
      "volume": "84510174"
    },
    {
      "datetime": "2020-08-01",
      "open": "117.96275",
      "high": "124.62025",
      "low": "116.67329",
      "close": "117.78127",
      "volume": "74056612"
    },
    {
      "datetime": "2020-07-01",
      "open": "114.87757",
      "high": "126.24403",
      "low": "110.03489",
      "close": "117.42786",
      "volume": "114195568"
    },
    {
      "datetime": "2020-06-01",
      "open": "119.05164",
      "high": "129.78769",
      "low": "110.68440",
      "close": "115.35516",
      "volume": "121011408"
    },
    {
      "datetime": "2020-05-01",
      "open": "123.19000",
      "high": "124.32000",
      "low": "106.79689",
      "close": "119.29999",
      "volume": "92888757"
    },
    {
      "datetime": "2020-04-01",
      "open": "106.36000",
      "high": "129.31000",
      "low": "104.52000",
      "close": "125.56000",
      "volume": "130697521"
    },
    {
      "datetime": "2020-03-01",
      "open": "130.75000",
      "high": "136.09000",
      "low": "90.56000",
      "close": "110.93000",
      "volume": "190088743"
    },
    {
      "datetime": "2020-02-01",
      "open": "144.25000",
      "high": "158.75000",
      "low": "126.36000",
      "close": "130.14999",
      "volume": "118153735"
    },
    {
      "datetime": "2020-01-01",
      "open": "135.00000",
      "high": "145.78999",
      "low": "133.20000",
      "close": "143.73000",
      "volume": "113442410"
    },
    {
      "datetime": "2019-12-01",
      "open": "134.45000",
      "high": "136.42000",
      "low": "130.69000",
      "close": "134.03999",
      "volume": "71941530"
    },
    {
      "datetime": "2019-11-01",
      "open": "134.50000",
      "high": "139.13000",
      "low": "132.75000",
      "close": "134.45000",
      "volume": "64921102"
    },
    {
      "datetime": "2019-10-01",
      "open": "145.59000",
      "high": "147.35001",
      "low": "130.89999",
      "close": "133.73000",
      "volume": "92807862"
    },
    {
      "datetime": "2019-09-01",
      "open": "134.85001",
      "high": "146.57001",
      "low": "133.33000",
      "close": "145.42000",
      "volume": "62116513"
    },
    {
      "datetime": "2019-08-01",
      "open": "148.89999",
      "high": "152.95000",
      "low": "128.83000",
      "close": "135.53000",
      "volume": "96293224"
    },
    {
      "datetime": "2019-07-01",
      "open": "139.60001",
      "high": "151.94000",
      "low": "139.13000",
      "close": "148.24001",
      "volume": "80175943"
    },
    {
      "datetime": "2019-06-01",
      "open": "127.10000",
      "high": "140.14999",
      "low": "127.06000",
      "close": "137.89999",
      "volume": "61342985"
    },
    {
      "datetime": "2019-05-01",
      "open": "140.55000",
      "high": "141.81000",
      "low": "126.85000",
      "close": "126.99000",
      "volume": "75537897"
    },
    {
      "datetime": "2019-04-01",
      "open": "141.50999",
      "high": "145.39000",
      "low": "136.25999",
      "close": "140.27000",
      "volume": "80199229"
    },
    {
      "datetime": "2019-03-01",
      "open": "139.31000",
      "high": "142.12000",
      "low": "133.58000",
      "close": "141.10001",
      "volume": "74688899"
    },
    {
      "datetime": "2019-02-01",
      "open": "134.97000",
      "high": "140.49001",
      "low": "132.12000",
      "close": "138.13000",
      "volume": "68462024"
    },
    {
      "datetime": "2019-01-01",
      "open": "112.01000",
      "high": "135.41000",
      "low": "111.69000",
      "close": "134.42000",
      "volume": "120388583"
    },
    {
      "datetime": "2018-12-01",
      "open": "125.67000",
      "high": "126.59000",
      "low": "105.94000",
      "close": "113.67000",
      "volume": "115600988"
    },
    {
      "datetime": "2018-11-01",
      "open": "115.50000",
      "high": "125.06000",
      "low": "114.54000",
      "close": "124.27000",
      "volume": "141719180"
    },
    {
      "datetime": "2018-10-01",
      "open": "151.71001",
      "high": "154.36000",
      "low": "114.09000",
      "close": "115.43000",
      "volume": "198093763"
    },
    {
      "datetime": "2018-09-01",
      "open": "145.98000",
      "high": "153.35001",
      "low": "144.81000",
      "close": "151.21001",
      "volume": "95868785"
    },
    {
      "datetime": "2018-08-01",
      "open": "144.75999",
      "high": "147.92999",
      "low": "142.00000",
      "close": "146.48000",
      "volume": "79196876"
    },
    {
      "datetime": "2018-07-01",
      "open": "138.28000",
      "high": "150.53999",
      "low": "138.20000",
      "close": "144.92999",
      "volume": "95015127"
    },
    {
      "datetime": "2018-06-01",
      "open": "142.42999",
      "high": "147.52000",
      "low": "137.45000",
      "close": "139.70000",
      "volume": "90247351"
    },
    {
      "datetime": "2018-05-01",
      "open": "144.64999",
      "high": "146.20000",
      "low": "139.89999",
      "close": "141.31000",
      "volume": "88812832"
    },
    {
      "datetime": "2018-04-01",
      "open": "153.34000",
      "high": "162.00000",
      "low": "144.11000",
      "close": "144.96001",
      "volume": "122894861"
    },
    {
      "datetime": "2018-03-01",
      "open": "155.53000",
      "high": "162.11000",
      "low": "148.53999",
      "close": "153.42999",
      "volume": "94317107"
    },
    {
      "datetime": "2018-02-01",
      "open": "163.19000",
      "high": "164.13000",
      "low": "144.39999",
      "close": "155.83000",
      "volume": "102364921"
    },
    {
      "datetime": "2018-01-01",
      "open": "154.50000",
      "high": "171.13000",
      "low": "153.53999",
      "close": "163.70000",
      "volume": "145086550"
    },
    {
      "datetime": "2017-12-01",
      "open": "154.39999",
      "high": "157.85001",
      "low": "151.49001",
      "close": "153.42000",
      "volume": "88788538"
    },
    {
      "datetime": "2017-11-01",
      "open": "154.10001",
      "high": "154.89999",
      "low": "146.21001",
      "close": "153.97000",
      "volume": "89776130"
    },
    {
      "datetime": "2017-10-01",
      "open": "145.35001",
      "high": "162.50999",
      "low": "145.21001",
      "close": "154.06000",
      "volume": "125345669"
    },
    {
      "datetime": "2017-09-01",
      "open": "142.98000",
      "high": "147.42000",
      "low": "141.64000",
      "close": "145.08000",
      "volume": "78298096"
    },
    {
      "datetime": "2017-08-01",
      "open": "145.00000",
      "high": "145.67000",
      "low": "139.13000",
      "close": "143.03000",
      "volume": "80295410"
    },
    {
      "datetime": "2017-07-01",
      "open": "153.58000",
      "high": "156.03000",
      "low": "143.64000",
      "close": "144.67000",
      "volume": "93293593"
    },
    {
      "datetime": "2017-06-01",
      "open": "152.80000",
      "high": "157.20000",
      "low": "150.80000",
      "close": "153.83000",
      "volume": "83978157"
    },
    {
      "datetime": "2017-05-01",
      "open": "160.05000",
      "high": "160.42000",
      "low": "149.78999",
      "close": "152.63000",
      "volume": "103330920"
    },
    {
      "datetime": "2017-04-01",
      "open": "173.82001",
      "high": "176.33000",
      "low": "159.60001",
      "close": "160.28999",
      "volume": "100053430"
    },
    {
      "datetime": "2017-03-01",
      "open": "180.48000",
      "high": "182.55000",
      "low": "172.09000",
      "close": "174.14000",
      "volume": "79480073"
    },
    {
      "datetime": "2017-02-01",
      "open": "175.00000",
      "high": "182.78999",
      "low": "172.89000",
      "close": "179.82001",
      "volume": "59820784"
    },
    {
      "datetime": "2017-01-01",
      "open": "167.00000",
      "high": "179.25000",
      "low": "165.34000",
      "close": "174.52000",
      "volume": "88764879"
    },
    {
      "datetime": "2016-12-01",
      "open": "161.95000",
      "high": "169.95000",
      "low": "158.30000",
      "close": "165.99001",
      "volume": "69470244"
    },
    {
      "datetime": "2016-11-01",
      "open": "153.50000",
      "high": "164.66000",
      "low": "151.00000",
      "close": "162.22000",
      "volume": "76342436"
    },
    {
      "datetime": "2016-10-01",
      "open": "158.06000",
      "high": "158.53000",
      "low": "147.78999",
      "close": "153.69000",
      "volume": "78327202"
    },
    {
      "datetime": "2016-09-01",
      "open": "158.32001",
      "high": "165.00000",
      "low": "153.21001",
      "close": "158.85001",
      "volume": "69953834"
    },
    {
      "datetime": "2016-08-01",
      "open": "160.64999",
      "high": "164.95000",
      "low": "157.85001",
      "close": "158.88000",
      "volume": "69706627"
    },
    {
      "datetime": "2016-07-01",
      "open": "152.19000",
      "high": "163.60001",
      "low": "149.92000",
      "close": "160.62000",
      "volume": "69955097"
    },
    {
      "datetime": "2016-06-01",
      "open": "153.00000",
      "high": "155.48000",
      "low": "142.50000",
      "close": "151.78000",
      "volume": "77122391"
    },
    {
      "datetime": "2016-05-01",
      "open": "146.56000",
      "high": "153.81000",
      "low": "142.89999",
      "close": "153.74001",
      "volume": "75251780"
    },
    {
      "datetime": "2016-04-01",
      "open": "150.50999",
      "high": "153.52000",
      "low": "142.61000",
      "close": "145.94000",
      "volume": "94482439"
    },
    {
      "datetime": "2016-03-01",
      "open": "132.24001",
      "high": "153.10001",
      "low": "132.03000",
      "close": "151.45000",
      "volume": "105747104"
    },
    {
      "datetime": "2016-02-01",
      "open": "124.40000",
      "high": "134.92000",
      "low": "116.90000",
      "close": "131.03000",
      "volume": "106716049"
    },
    {
      "datetime": "2016-01-01",
      "open": "135.60001",
      "high": "136.89000",
      "low": "118.00000",
      "close": "124.79000",
      "volume": "126232993"
    },
    {
      "datetime": "2015-12-01",
      "open": "139.58000",
      "high": "141.39999",
      "low": "133.91000",
      "close": "137.62000",
      "volume": "97680742"
    },
    {
      "datetime": "2015-11-01",
      "open": "140.50000",
      "high": "142.80000",
      "low": "131.64999",
      "close": "139.42000",
      "volume": "85437032"
    },
    {
      "datetime": "2015-10-01",
      "open": "145.31000",
      "high": "153.14999",
      "low": "137.33000",
      "close": "140.08000",
      "volume": "120629541"
    },
    {
      "datetime": "2015-09-01",
      "open": "144.91000",
      "high": "149.67999",
      "low": "141.14999",
      "close": "144.97000",
      "volume": "81813217"
    },
    {
      "datetime": "2015-08-01",
      "open": "161.70000",
      "high": "161.85001",
      "low": "140.62000",
      "close": "147.89000",
      "volume": "93792471"
    },
    {
      "datetime": "2015-07-01",
      "open": "163.97000",
      "high": "173.78000",
      "low": "158.50000",
      "close": "161.99001",
      "volume": "93854723"
    },
    {
      "datetime": "2015-06-01",
      "open": "170.21001",
      "high": "171.56000",
      "low": "162.12000",
      "close": "162.66000",
      "volume": "80848189"
    },
    {
      "datetime": "2015-05-01",
      "open": "173.20000",
      "high": "176.30000",
      "low": "168.84000",
      "close": "169.64999",
      "volume": "57929671"
    },
    {
      "datetime": "2015-04-01",
      "open": "160.23000",
      "high": "175.13000",
      "low": "158.39000",
      "close": "171.28999",
      "volume": "93836029"
    },
    {
      "datetime": "2015-03-01",
      "open": "161.67999",
      "high": "165.35001",
      "low": "153.39999",
      "close": "160.50000",
      "volume": "104911849"
    },
    {
      "datetime": "2015-02-01",
      "open": "154.00000",
      "high": "164.99001",
      "low": "151.50999",
      "close": "161.94000",
      "volume": "77656864"
    },
    {
      "datetime": "2015-01-01",
      "open": "161.31000",
      "high": "163.31000",
      "low": "149.52000",
      "close": "153.31000",
      "volume": "117419989"
    },
    {
      "datetime": "2014-12-01",
      "open": "161.64000",
      "high": "164.52000",
      "low": "150.50000",
      "close": "160.44000",
      "volume": "101618769"
    },
    {
      "datetime": "2014-11-01",
      "open": "164.25000",
      "high": "164.97000",
      "low": "159.80000",
      "close": "162.17000",
      "volume": "80023025"
    },
    {
      "datetime": "2014-10-01",
      "open": "189.91000",
      "high": "190.89000",
      "low": "161.10001",
      "close": "164.39999",
      "volume": "146286082"
    },
    {
      "datetime": "2014-09-01",
      "open": "192.67999",
      "high": "195.00000",
      "low": "188.12000",
      "close": "189.83000",
      "volume": "64212824"
    },
    {
      "datetime": "2014-08-01",
      "open": "190.50000",
      "high": "194.13000",
      "low": "183.58000",
      "close": "192.30000",
      "volume": "53464238"
    },
    {
      "datetime": "2014-07-01",
      "open": "181.70000",
      "high": "196.39999",
      "low": "181.70000",
      "close": "191.67000",
      "volume": "97623913"
    },
    {
      "datetime": "2014-06-01",
      "open": "184.75999",
      "high": "187.64999",
      "low": "179.27000",
      "close": "181.27000",
      "volume": "78536258"
    },
    {
      "datetime": "2014-05-01",
      "open": "196.31000",
      "high": "196.74001",
      "low": "182.33000",
      "close": "184.36000",
      "volume": "70040432"
    },
    {
      "datetime": "2014-04-01",
      "open": "193.12000",
      "high": "199.21001",
      "low": "187.00999",
      "close": "196.47000",
      "volume": "118484558"
    },
    {
      "datetime": "2014-03-01",
      "open": "183.33000",
      "high": "195.63000",
      "low": "182.21001",
      "close": "192.49001",
      "volume": "118994796"
    },
    {
      "datetime": "2014-02-01",
      "open": "176.02000",
      "high": "186.12000",
      "low": "172.19000",
      "close": "185.17000",
      "volume": "87112654"
    },
    {
      "datetime": "2014-01-01",
      "open": "187.21001",
      "high": "190.81000",
      "low": "175.34000",
      "close": "176.67999",
      "volume": "123649280"
    },
    {
      "datetime": "2013-12-01",
      "open": "179.46001",
      "high": "187.78999",
      "low": "172.73000",
      "close": "187.57001",
      "volume": "98015900"
    },
    {
      "datetime": "2013-11-01",
      "open": "179.81000",
      "high": "186.24001",
      "low": "177.31000",
      "close": "179.67999",
      "volume": "101007228"
    },
    {
      "datetime": "2013-10-01",
      "open": "185.34000",
      "high": "186.99001",
      "low": "172.57001",
      "close": "179.21001",
      "volume": "131003002"
    },
    {
      "datetime": "2013-09-01",
      "open": "183.63000",
      "high": "194.89000",
      "low": "182.31000",
      "close": "185.17999",
      "volume": "71509256"
    },
    {
      "datetime": "2013-08-01",
      "open": "196.64999",
      "high": "197.17000",
      "low": "181.10001",
      "close": "182.27000",
      "volume": "73104007"
    },
    {
      "datetime": "2013-07-01",
      "open": "192.14999",
      "high": "200.94000",
      "low": "190.25999",
      "close": "195.03999",
      "volume": "88101650"
    },
    {
      "datetime": "2013-06-01",
      "open": "208.25000",
      "high": "210.05000",
      "low": "188.41000",
      "close": "191.11000",
      "volume": "80232571"
    },
    {
      "datetime": "2013-05-01",
      "open": "201.87000",
      "high": "211.98000",
      "low": "199.20000",
      "close": "208.02000",
      "volume": "90700272"
    },
    {
      "datetime": "2013-04-01",
      "open": "212.80000",
      "high": "214.89000",
      "low": "187.67999",
      "close": "202.53999",
      "volume": "108684680"
    },
    {
      "datetime": "2013-03-01",
      "open": "200.64999",
      "high": "215.89999",
      "low": "199.36000",
      "close": "213.30000",
      "volume": "76040619"
    },
    {
      "datetime": "2013-02-01",
      "open": "204.64999",
      "high": "205.35001",
      "low": "197.50999",
      "close": "200.83000",
      "volume": "64149096"
    },
    {
      "datetime": "2013-01-01",
      "open": "194.09000",
      "high": "208.58000",
      "low": "190.39000",
      "close": "203.07001",
      "volume": "87656638"
    },
    {
      "datetime": "2012-12-01",
      "open": "190.75999",
      "high": "196.45000",
      "low": "186.94000",
      "close": "191.55000",
      "volume": "79327164"
    },
    {
      "datetime": "2012-11-01",
      "open": "194.67999",
      "high": "198.00000",
      "low": "184.78000",
      "close": "190.07001",
      "volume": "81897155"
    },
    {
      "datetime": "2012-10-01",
      "open": "208.00999",
      "high": "211.78999",
      "low": "190.56000",
      "close": "194.53000",
      "volume": "96165835"
    },
    {
      "datetime": "2012-09-01",
      "open": "196.61000",
      "high": "208.32001",
      "low": "193.25000",
      "close": "207.45000",
      "volume": "77349063"
    }
  ],
  "status": "ok"
}