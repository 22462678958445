import React from 'react'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { ResponsiveSankey } from '@nivo/sankey'
import { Design } from '../../Helpers/Design';
import { CardBody, Card, Text } from '@chakra-ui/react';

export default function IncomeSankeyChart(props) {

    if (!props.IncomeStatement) {
        return <LoadingComponent />;
      }

    const data = {
        "nodes": [
          {
            "id": "Revenue",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "Cost of Revenue",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "Gross Profit",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "Operating profit",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "Net profit",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "Operating Expenses",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "R&D",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "cost of Goods And Services Sold",
            "nodeColor": "hsl(24, 70%, 50%)"
          },
          {
            "id": "SG&A",
            "nodeColor": "hsl(24, 70%, 50%)"
          },

        ],
        "links": [
            {
                "source": "Revenue",
                "target": "Gross Profit",
                "value": props.IncomeStatement.annualReports[0].grossProfit
            },
            {
                "source": "Revenue",
                "target": "Cost of Revenue",
                "value": props.IncomeStatement.annualReports[0].costOfRevenue

            },
            {
                "source": "Gross Profit",
                "target": "Operating profit",
                "value": props.IncomeStatement.annualReports[0].operatingIncome

            },
            {
                "source": "Operating profit",
                "target": "Net profit",
                "value": props.IncomeStatement.annualReports[0].netIncome

            },
            {
              "source": "Gross Profit",
              "target": "Operating Expenses",
              "value":  props.IncomeStatement.annualReports[0].operatingExpenses

            },
            {
              "source": "Operating Expenses",
              "target": "R&D",
              "value": props.IncomeStatement.annualReports[0].researchAndDevelopment

            },
            {
              "source": "Operating Expenses",
              "target": "SG&A",
              "value": props.IncomeStatement.annualReports[0].sellingGeneralAndAdministrative

            },
            {
              "source": "Cost of Revenue",
              "target": "cost of Goods And Services Sold",
              "value": props.IncomeStatement.annualReports[0].costofGoodsAndServicesSold

            },
        
        ]
      }


  return (
    <ResponsiveSankey
    data={data}
    margin={{ top: 40, right: 40, bottom: 40, left: 50 }}
    align="justify"
    theme={Design}
    colors={['#4541BB']}
    labelTextColor="white"
    nodeOpacity={1}
    nodeThickness={18}
    nodeInnerPadding={3}
    nodeSpacing={24}
    nodeBorderWidth={0}
    nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
    linkOpacity={0.5}
    linkHoverOthersOpacity={0.1}
    enableLinkGradient={true}
    labelPosition="inside"
    nodeTooltip={(node) => {
      return(
        <Card bg={"#1c1c1c"} color={"white"} boxShadow='xs' shadow={false}>
          <CardBody>
            <Text>{node.node.id} : {node.node.value / 1000000} in Mio {props.IncomeStatement.annualReports[0].reportedCurrency}</Text>
          </CardBody>
        </Card>
      )
    }}
    linkTooltip={(node) => {
      return <div></div>
    }}
    labelOrientation="horizontal"
    labelPadding={16}
    animate={true}
    motionStiffness={140}
    motionDamping={13}
  />
  )
}
