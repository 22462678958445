import React from 'react'
import { 
  Spacer, 
  Card, 
  Flex, 
  Text, 
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure 
} from '@chakra-ui/react'

export default function Footer() {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  return (
    <Card width="100%" boxShadow='xs' height="100%" bg={"#262626"} color="white">
        <Flex height="100vh" align="center">
            <Text fontSize='md' ml={4} color={"#718096"} fontWeight="500">
              © 2023 portfoliopicker.net All Rights Reserved
            </Text>
            <Spacer />
              <Button variant='link' onClick={onOpen} margin={8}>
                Impressum
              </Button>
              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                      Delete Customer
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button colorScheme='red' onClick={onClose} ml={3}>
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
        </Flex>
    </Card>
  )
}
