import React from 'react'
import { Stat, StatNumber, Flex, Card, CardBody, StatLabel} from '@chakra-ui/react'
import LoadingComponent from '../LoadingComponent/LoadingComponent'

export default function Numberview(props) {

  if(!props.number){
    return <LoadingComponent/>
  }

  return (
    <Card 
    width="100%" 
    boxShadow='xs' 
    height="100%" 
    bg={"#262626"}
    color="white"
    overflow={"hidden"}
    direction={{ base: 'column'}}
    >
        <CardBody>
            <Flex alignItems={"center"} justifyContent="center" height="100%">
                <Stat textAlign="center">
                <StatLabel>{props.title}</StatLabel>
                    <StatNumber>{props.number} {props.symbol}</StatNumber>
                </Stat>
            </Flex>
        </CardBody>
    </Card>
    
  )
}
