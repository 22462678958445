 export const layouts = {
    lg: [
      { i: "header", x: 0, y: 0, w: 12, h: 2, static: true},
      { i: "adsbottom", x: 0, y: 2, w: 12, h: 3, static: true},
      { i: "DpS", x: 0, y: 5, w: 1.5, h: 3, static: true},
      { i: "profit", x: 1.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "eps", x: 3, y: 5, w: 1.5, h: 3, static: true},
      { i: "pmargin", x: 4.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "mcap", x: 6, y: 5, w: 1.5, h: 3, static: true},
      { i: "per", x: 7.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "qeg", x: 9, y: 5, w: 1.5, h: 3, static: true},
      { i: "shareout", x: 10.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "rangle", x: 0, y: 8, w: 3, h: 14, static: true},
      { i: "revenue", x: 3, y: 8, w: 5, h: 14, static: true},
      { i: "monte", x: 8, y: 8, w: 4, h: 14, static: true},
      { i: "isankey", x: 0, y: 22, w: 6, h: 13, static: true},
      { i: "ebit", x: 6, y: 22, w: 6, h: 13, static: true},
      { i: "footer", x: 0, y: 35, w: 12, h: 3, static: true},
    ],
    md: [
      { i: "header", x: 0, y: 0, w: 12, h: 2, static: true},
      { i: "adsbottom", x: 0, y: 2, w: 12, h: 3, static: true},
      { i: "DpS", x: 0, y: 5, w: 1.5, h: 3, static: true},
      { i: "profit", x: 1.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "eps", x: 3, y: 5, w: 1.5, h: 3, static: true},
      { i: "pmargin", x: 4.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "mcap", x: 6, y: 5, w: 1.5, h: 3, static: true},
      { i: "per", x: 7.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "qeg", x: 9, y: 5, w: 1.5, h: 3, static: true},
      { i: "shareout", x: 10.5, y: 5, w: 1.5, h: 3, static: true},
      { i: "rangle", x: 0, y: 8, w: 4, h: 12, static: true},
      { i: "revenue", x: 4, y: 8, w: 4, h: 12, static: true},
      { i: "monte", x: 8, y: 8, w: 4, h: 12, static: true},
      { i: "isankey", x: 0, y: 20, w: 6, h: 13, static: true},
      { i: "ebit", x: 6, y: 20, w: 6, h: 13, static: true},
      { i: "footer", x: 0, y: 33, w: 12, h: 3, static: true},
    ],
    sm: [
      { i: "header", x: 0, y: 0, w: 4, h: 2.5, static: true},
      { i: "adsbottom", x: 0, y: 2.5, w: 4, h: 2.5, static: true},
      { i: "DpS", x: 0, y: 5, w: 2, h: 3, static: true},
      { i: "profit", x: 2, y: 5, w: 2, h: 3, static: true},
      { i: "eps", x: 0, y: 8, w: 2, h: 3, static: true},
      { i: "pmargin", x: 2, y: 8, w: 2, h: 3, static: true},
      { i: "mcap", x: 0, y: 11, w: 2, h: 3, static: true},
      { i: "per", x: 2, y: 11, w: 2, h: 3, static: true},
      { i: "qeg", x: 0, y: 14, w: 2, h: 3, static: true},
      { i: "shareout", x: 2, y: 14, w: 2, h: 3, static: true},
      { i: "revenue", x: 0, y: 17, w: 4, h: 8, static: true},
      { i: "rangle", x: 0, y: 25, w: 4, h: 8, static: true},
      { i: "monte", x: 0, y: 33, w: 4, h: 8, static: true},
      { i: "isankey", x: 0, y: 41, w: 4, h: 8, static: true},
      { i: "ebit", x: 0, y: 49, w: 4, h: 8, static: true},
      { i: "footer", x: 0, y: 57, w: 5, h: 2.5, static: true},
    ],
  };