import './App.css';
import DashboardItem from './Components/DashboardItem/DashboardItem';
import Header from './Components/Header/Header';
import Numberview from './Components/NumberView/Numberview';
import { Responsive, WidthProvider } from "react-grid-layout";
import { layouts } from './Helpers/Layouts';
import RevenueBarChart from './Components/RevenueBarChart/RevenueBarChart';
import { getIncomeStatement } from './Routes/IncomeStatement/getIncomeStatement';
import { getCompanyOverview } from './Routes/CompanyOverview/getCompanyOverview';
import { getTickerData } from './Routes/TickerData/getTickerData';
import { mockupCompanyOverview } from './Routes/CompanyOverview/moCompanyOverview'
import { mockupIncomeStatement } from './Routes/IncomeStatement/moIncomeStatement';
import { mockupTickerData } from "./Routes/TickerData/moTickerData";
import YieldTriangle from './Components/YieldTriangle/YieldTriangle';
import  EbitBarChart  from './Components/EbitBarChart/EbitBarChart'
import IncomeSankeyChart from './Components/IncomeSankeyChart/IncomeSankeyChart';
import MonteCarlo from './Components/MonteCarlo/MonteCarlo';
import Footer from './Components/Footer/Footer';
import { useEffect, useState } from 'react';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import { firebaseConfig } from './firebaseConfig';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const ResponsiveGridLayout = WidthProvider(Responsive);

function App() {

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  const [incomeStatement, setIncomeStatement] = useState(mockupIncomeStatement);
  const [companyOverview, setCompanyOverview] = useState(mockupCompanyOverview);
  const [tickerData, setTickerData] = useState(mockupTickerData);


  const [stock, setStock] = useState('IBM');

  useEffect(() => {

    const DEBUG = true;


    async function fetchData() {
      try {
        const is = await getIncomeStatement(stock);
        setIncomeStatement(is);

        const co = await getCompanyOverview(stock);
        setCompanyOverview(co);

        const td = await getTickerData(stock);
        setTickerData(td)

      } catch (error) {
        console.error(error);
      }
    }

    if(stock){
      if(DEBUG){
        setIncomeStatement(mockupIncomeStatement);
        setCompanyOverview(mockupCompanyOverview);

      }else {
        fetchData();
      }
    }
  }, [stock]);

  if(companyOverview.Note || incomeStatement.Note){
    return(
      <LoadingScreen setStock={setStock} />
    )
  }
  
  

  return (
    <ResponsiveGridLayout
    className="layout"
    layouts={layouts}
    breakpoints={{ lg: 1500, md: 1280, sm: 768}}
    cols={{ lg: 12, md: 12, sm: 4 }}
    rowHeight={30}
  >
    <div key="header">
      <Header setStock={setStock} stock={stock}/>
    </div>
    <div key="DpS">
        <Numberview title="Dividend per share" number={companyOverview.DividendPerShare} symbol={"$"}/>
    </div>
    <div key="profit">
        <Numberview title="Profit last year in Mio" number={incomeStatement.annualReports[0].grossProfit / 1000000} symbol={"$"}/>
    </div>
    <div key="eps">
        <Numberview title="Earnings per share" number={companyOverview.EPS} symbol={"$"}/>
    </div>
    <div key="pmargin">
        <Numberview title="Shares outstanding" number={companyOverview.SharesOutstanding} symbol={""}/>
    </div>
    <div key="mcap">
        <Numberview title="Market Cap. in Mio" number={(companyOverview.MarketCapitalization / 1000000).toFixed(2)} symbol={"$"}/>
    </div>
    <div key="per">
        <Numberview title="Price Earning Ratio" number={companyOverview.PERatio} symbol={"$"}/>
    </div>
    <div key="qeg">
        <Numberview title="Quaterly Earnings Growth" number={(Number(companyOverview.QuarterlyEarningsGrowthYOY) * 10).toFixed(2)} symbol={"%"}/>
    </div>
    <div key="shareout">
        <Numberview title="Quaterly Revenue Growth" number={(companyOverview.QuarterlyRevenueGrowthYOY * 10) * 10 } symbol={"%"}/>
    </div>
    <div key="revenue">
      <DashboardItem title="Revenue">
        <RevenueBarChart IncomeStatement={incomeStatement}/>
      </DashboardItem>
    </div>
    <div key="ebit">
      <DashboardItem title="Ebit">
        <EbitBarChart IncomeStatement={incomeStatement}/>
      </DashboardItem>
    </div>
    <div key="isankey">
      <DashboardItem title="Income Statement Sankey">
        <IncomeSankeyChart IncomeStatement={incomeStatement}/>
      </DashboardItem>
    </div>
    <div key="rangle">
      <DashboardItem title="Yield triangle">
        <YieldTriangle tickerData={tickerData}/>
      </DashboardItem>
    </div>
    <div key="monte">
      <DashboardItem title="Stock Price">
        <MonteCarlo tickerData={tickerData}/>
      </DashboardItem>
    </div>
    <div key="adsbottom">
      <DashboardItem title="ad">
      </DashboardItem>
    </div>
    <div key="footer">
      <Footer />
    </div>
  </ResponsiveGridLayout>
  );
}

export default App;
