import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { Design } from '../../Helpers/Design'
import LoadingComponent from '../LoadingComponent/LoadingComponent';

export default function MonteCarlo(props) {

    if (!props.tickerData) {
      return <LoadingComponent />;
    }

    // const closePrice = props.tickerData.values.map(item => item.close)



    const data = [
        {
          id: 'kurs',
          data: props.tickerData.values.map(value => ({
            x: new Date(value.datetime),
            y: parseFloat(value.close)
          })),
        },
      ];



  return (
    <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
    xFormat="time:%Y-%m-%d"
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
    axisTop={null}
    axisRight={null}
    theme={Design}
    enableGridX={false}
    enableGridY={true}
    curve="monotoneX"
    isInteractive={false}
    enablePoints={false}
    enableCrosshair={false}
    useMesh={true}
    colors={{ scheme: 'category10' }}
    lineWidth={2}
    areaOpacity={0.2}
    enableSlices="x"
    axisBottom={{
        format: '%Y-%m-%d', // Date format for X-axis ticks (e.g., Jan 01)
        tickRotation: -35, // Rotate the X-axis ticks by -45 degrees
      }}
  />
  )
}
