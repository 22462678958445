import { getObjectByDatetime } from "./getObjectByDatetime"
import { calculateProfit } from "./calculateProfit"

export function getTriangleData(startyear, props){


    const data = [];
    const currentYear = new Date().getFullYear();

    for (let year = startyear; year <= currentYear; year++) {
    const dataEntry = { id: String(year), data: [] };

        for (let x = startyear; x <= year; x++) {
        const openPrice = getObjectByDatetime(props.tickerData, `${x}-01-01`).open;
        const closePrice = getObjectByDatetime(props.tickerData, `${year + 1}-01-01`).close;
        const profit = calculateProfit(openPrice, closePrice);

        dataEntry.data.push({ x: x, y: profit });
        }

        data.push(dataEntry);
    }

  return data;


}