export function getObjectByDatetime(data, datetime) {
  
    for (let i = 0; i < data.values.length; i++) {
      if (data.values[i].datetime === datetime) {
        return data.values[i];
      }
    }

    let last = ""

    for (let i = 0; i < data.values.length; i++) {
      if (data.values[i].datetime === "2023-06-01") {
        last = data.values[i];
      }
    }


    return last; // Object not found
  }