export function getOldestObjectWith0101(values) {
    let oldestObject = null;
  
    for (let i = 0; i < values.length; i++) {
      const datetime = values[i].datetime;
      if (datetime.endsWith('-01-01')) {
        if (!oldestObject || datetime < oldestObject.datetime) {
          oldestObject = values[i];
        }
      }
    }
  
    return parseInt(oldestObject.datetime.replace("-01-01", ""));
  }