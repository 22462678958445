export const Nasdaq100 = [
    {
        "ticker": "ADBE",
        "name": "Adobe Inc."
      },
      {
        "ticker": "ADP",
        "name": "ADP"
      },
      {
        "ticker": "ABNB",
        "name": "Airbnb"
      },
      {
        "ticker": "ALGN",
        "name": "Align Technology"
      },
      {
        "ticker": "GOOGL",
        "name": "Alphabet Inc. (Class A)"
      },
      {
        "ticker": "GOOG",
        "name": "Alphabet Inc. (Class C)"
      },
      {
        "ticker": "AMZN",
        "name": "Amazon"
      },
      {
        "ticker": "AMD",
        "name": "Advanced Micro Devices Inc."
      },
      {
        "ticker": "AEP",
        "name": "American Electric Power"
      },
      {
        "ticker": "AMGN",
        "name": "Amgen"
      },
      {
        "ticker": "ADI",
        "name": "Analog Devices"
      },
      {
        "ticker": "ANSS",
        "name": "Ansys"
      },
      {
        "ticker": "AAPL",
        "name": "Apple Inc."
      },
      {
        "ticker": "AMAT",
        "name": "Applied Materials"
      },
      {
        "ticker": "ASML",
        "name": "ASML Holding"
      },
      {
        "ticker": "AZN",
        "name": "AstraZeneca"
      },
      {
        "ticker": "TEAM",
        "name": "Atlassian"
      },
      {
        "ticker": "ADSK",
        "name": "Autodesk"
      },
      {
        "ticker": "BKR",
        "name": "Baker Hughes"
      },
      {
        "ticker": "BIIB",
        "name": "Biogen"
      },
      {
        "ticker": "BKNG",
        "name": "Booking Holdings"
      },
      {
        "ticker": "AVGO",
        "name": "Broadcom Inc."
      },
      {
        "ticker": "CDNS",
        "name": "Cadence Design Systems"
      },
      {
        "ticker": "CHTR",
        "name": "Charter Communications"
      },
      {
        "ticker": "CTAS",
        "name": "Cintas"
      },
      {
        "ticker": "CSCO",
        "name": "Cisco"
      },
      {
        "ticker": "CTSH",
        "name": "Cognizant"
      },
      {
        "ticker": "CMCSA",
        "name": "Comcast"
      },
      {
        "ticker": "CEG",
        "name": "Constellation Energy"
      },
      {
        "ticker": "CPRT",
        "name": "Copart"
      },
      {
        "ticker": "CSGP",
        "name": "CoStar Group"
      },
      {
        "ticker": "COST",
        "name": "Costco"
      },
      {
        "ticker": "CRWD",
        "name": "CrowdStrike"
      },
      {
        "ticker": "CSX",
        "name": "CSX Corporation"
      },
      {
        "ticker": "DDOG",
        "name": "Datadog"
      },
      {
        "ticker": "DXCM",
        "name": "DexCom"
      },
      {
        "ticker": "FANG",
        "name": "Diamondback Energy"
      },
      {
        "ticker": "DLTR",
        "name": "Dollar Tree"
      },
      {
        "ticker": "EBAY",
        "name": "eBay"
      },
      {
        "ticker": "EA",
        "name": "Electronic Arts"
      },
      {
        "ticker": "ENPH",
        "name": "Enphase Energy"
      },
      {
        "ticker": "EXC",
        "name": "Exelon"
      },
      {
        "ticker": "FAST",
        "name": "Fastenal"
      },
      {
        "ticker": "FTNT",
        "name": "Fortinet"
      },
      {
        "ticker": "GEHC",
        "name": "GE HealthCare"
      },
      {
        "ticker": "GILD",
        "name": "Gilead Sciences"
      },
      {
        "ticker": "GFS",
        "name": "GlobalFoundries"
      },
      {
        "ticker": "HON",
        "name": "Honeywell"
      },
      {
        "ticker": "IDXX",
        "name": "Idexx Laboratories"
      },
      {
        "ticker": "ILMN",
        "name": "Illumina, Inc."
      },
      {
        "ticker": "INTC",
        "name": "Intel"
      },
      {
        "ticker": "INTU",
        "name": "Intuit"
      },
      {
        "ticker": "ISRG",
        "name": "Intuitive Surgical"
      },
      {
        "ticker": "JD",
        "name": "JD.com"
      },
      {
        "ticker": "KDP",
        "name": "Keurig Dr Pepper"
      },
      {
        "ticker": "KLAC",
        "name": "KLA Corporation"
      },
      {
        "ticker": "KHC",
        "name": "Kraft Heinz"
      },
      {
        "ticker": "LRCX",
        "name": "Lam Research"
      },
      {
        "ticker": "LCID",
        "name": "Lucid Motors"
      },
      {
        "ticker": "LULU",
        "name": "Lululemon"
      },
      {
        "ticker": "MAR",
        "name": "Marriott International"
      },
      {
        "ticker": "MRVL",
        "name": "Marvell Technology"
      },
      {
        "ticker": "MELI",
        "name": "MercadoLibre"
      },
      {
        "ticker": "META",
        "name": "Meta Platforms"
      },
      {
        "ticker": "MCHP",
        "name": "Microchip Technology"
      },
      {
        "ticker": "MU",
        "name": "Micron Technology"
      },
      {
        "ticker": "MSFT",
        "name": "Microsoft"
      },
      {
        "ticker": "MRNA",
        "name": "Moderna"
      },
      {
        "ticker": "MDLZ",
        "name": "Mondelēz International"
      },
      {
        "ticker": "MNST",
        "name": "Monster Beverage"
      },
      {
        "ticker": "NFLX",
        "name": "Netflix"
      },
      {
        "ticker": "NVDA",
        "name": "Nvidia"
      },
      {
        "ticker": "NXPI",
        "name": "NXP"
      },
      {
        "ticker": "ORLY",
        "name": "O'Reilly Automotive"
      },
      {
        "ticker": "ODFL",
        "name": "Old Dominion Freight Line"
      },
      {
        "ticker": "ON",
        "name": "Onsemi"
      },
      {
        "ticker": "PCAR",
        "name": "Paccar"
      },
      {
        "ticker": "PANW",
        "name": "Palo Alto Networks"
      },
      {
        "ticker": "PAYX",
        "name": "Paychex"
      },
      {
        "ticker": "PYPL",
        "name": "PayPal"
      },
      {
        "ticker": "PDD",
        "name": "PDD Holdings"
      },
      {
        "ticker": "PEP",
        "name": "PepsiCo"
      },
      {
        "ticker": "QCOM",
        "name": "Qualcomm"
      },
      {
        "ticker": "REGN",
        "name": "Regeneron"
      },
      {
        "ticker": "ROST",
        "name": "Ross Stores"
      },
      {
        "ticker": "SGEN",
        "name": "Seagen"
      },
      {
        "ticker": "SIRI",
        "name": "Sirius XM"
      },
      {
        "ticker": "SBUX",
        "name": "Starbucks"
      },
      {
        "ticker": "SNPS",
        "name": "Synopsys"
      },
      {
        "ticker": "TMUS",
        "name": "T-Mobile US"
      },
      {
        "ticker": "TSLA",
        "name": "Tesla, Inc."
      },
      {
        "ticker": "TXN",
        "name": "Texas Instruments"
      },
      {
        "ticker": "TTD",
        "name": "The Trade Desk"
      },
      {
        "ticker": "VRSK",
        "name": "Verisk"
      },
      {
        "ticker": "VRTX",
        "name": "Vertex Pharmaceuticals"
      },
      {
        "ticker": "WBA",
        "name": "Walgreens Boots Alliance"
      },
      {
        "ticker": "WBD",
        "name": "Warner Bros. Discovery"
      },
      {
        "ticker": "WDAY",
        "name": "Workday, Inc."
      },
      {
        "ticker": "XEL",
        "name": "Xcel Energy"
      },
      {
        "ticker": "ZM",
        "name": "Zoom Video Communications"
      },
      {
        "ticker": "ZS",
        "name": "Zscaler"
      }
]