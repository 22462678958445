import React from 'react'
import Header from '../Header/Header'
import { Responsive, WidthProvider } from "react-grid-layout";
import DashboardItem from '../DashboardItem/DashboardItem';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function LoadingScreen(props) {
  return (
    <ResponsiveGridLayout
        className="layout"
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        rowHeight={30}>
        <div key="header" data-grid={{x: 0, y: 0, w: 12, h: 2, static: true }}>
          <Header setStock={props.setStock}/>
        </div>
        <div key="message" data-grid={{x: 5, y: 10, w: 2, h: 2, static: true }}>
          <DashboardItem title="To much traffic, come back later :)">
          </DashboardItem>
        </div>
      </ResponsiveGridLayout>
  )
}
