import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Design } from '../../Helpers/Design';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

export default function RevenueBarChart(props) {
  if (!props.IncomeStatement) {
    return <LoadingComponent />;
  }

  const data = props.IncomeStatement.annualReports.sort(
    (a, b) => new Date(a.fiscalDateEnding) - new Date(b.fiscalDateEnding)
  );

  return (
    <>
      <ResponsiveBar
        data={data}
        keys={['totalRevenue']}
        colors={['#4541BB']}
        colorBy="index"
        theme={Design}
        indexBy="fiscalDateEnding"
        margin={{ top: 20, right: 20, bottom: 30, left: 80 }}
        enableLabel={false}
        padding={0.3}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Total Revenue in Mio. $',
          legendPosition: 'middle',
          legendOffset: -60,
          format: (value) => {
            if (value >= 1000000) {
              return `${value / 1000000}`;
            }
            return value;
          },
        }}
        axisBottom={{
          tickSize: 6,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Date',
          legendPosition: 'middle',
          legendOffset: 40,
          format: (value) => {
            return value.split('-')[0].trim();
          },
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        isInteractive={false}
      />
    </>
  );
}
