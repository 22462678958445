import React from 'react'
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { Design } from '../../Helpers/Design';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { CardBody, Card, Text } from '@chakra-ui/react';
import { getOldestObjectWith0101 } from '../../Helpers/getOldestObject';
import { getTriangleData } from '../../Helpers/getTriangleData';
export default function YieldTriangle(props) {
  

  if (!props.tickerData) {
    return <LoadingComponent />;
  }
  
  return (
    <ResponsiveHeatMap
        data={getTriangleData(getOldestObjectWith0101(props.tickerData.values),props)}
        margin={{ top: 30, right: 50, bottom: 60, left: 90 }}
        axisTop={false}
        theme={Design}
        valueFormat=""
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            legend: 'Buy',
            legendPosition: 'middle',
            legendOffset: 50,
            tickRotation: -35,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Sell',
            legendPosition: 'middle',
            legendOffset: -60
        }}
        colors={{
            type: 'diverging',
            scheme: 'red_yellow_green',
            divergeAt: 0.5,
            minValue: -30,
            maxValue: 30,
            steps: 5
        }}
        emptyColor="#555555"
        hoverTarget="cell"
        enableLabels={false}
        tooltip={(node) => {
          return(
            <Card bg={"#1c1c1c"} color={"white"} boxShadow='xs' shadow={false}>
              <CardBody>
                <Text fontSize='sm'>yield is <Text as='b'>{node.cell.data.y} %</Text></Text>
                <Text fontSize='sm'>bought at <Text as='b'>{node.cell.data.x}-01-01</Text></Text>
                <Text fontSize='sm'>sold at <Text as='b'>{node.cell.serieId}-12-31</Text></Text>
              </CardBody>
            </Card>
          )
        }}
    />
  )
}