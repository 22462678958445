import React from 'react'
import { Spacer, Card, Flex, Text, Select } from '@chakra-ui/react'
import "./Header.css"
import { Nasdaq100 } from './Searchdata'

export default function Header(props) {

  return (
    <Card width="100%" boxShadow='xs' height="100%" bg={"#262626"} color="white">
        <Flex height="100vh" align="center">
            <Text as='b' fontSize='2xl' ml={4}>
                Portfolio Picker
            </Text>
            <Spacer />
            <Text as='b' fontSize='xl' ml={4}>
                current Stock: <Text as='u'>{Nasdaq100.map((stock => {
                  var i = ""
                  if(stock.ticker === props.stock){
                    i = stock.name;
                  }
                  return i
                }))}</Text>
            </Text>
            <Spacer />
            <Select width={170} mr={4}  onChange={(event) => {
                props.setStock(event.target.value)
            }}>
                <option className="option">Select Stock</option>
                <option className="option-topic">Nasdaq100</option>
                {Nasdaq100.map((stock) => {
                  return <option className="option" value={stock.ticker}>{stock.name}</option>
                })}

            </Select>
        </Flex>
    </Card>
  )
}